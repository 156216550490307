export default [
  { name: "Bhagat Singh", link: "https://en.wikipedia.org/wiki/Bhagat_Singh" },
  {
    name: "Swamy Vivekananda",
    link: "https://en.wikipedia.org/wiki/Swami_Vivekananda",
  },
  {
    name: "Ramana Maharshi",
    link: "https://en.wikipedia.org/wiki/Ramana_Maharshi",
  },
  { name: "Steve Jobs", link: "https://en.wikipedia.org/wiki/Steve_Jobs" },
]
