export default [
  {
    name: "Malgudi Days - R K Narayan",
    link: "https://www.goodreads.com/book/show/14082.Malgudi_Days",
  },
  {
    name: "Complete Works of Swami Vivekananda - by Swami Vivekananda",
    link:
      "https://www.goodreads.com/book/show/35542819-complete-works-of-swami-vivekananda",
  },
  {
    name: "Who Am I? - Ramana Maharshi",
    link: "https://www.goodreads.com/book/show/551750.Who_Am_I",
  },
  {
    name: "Autobiography of a Yogi - Paramahansa Yogananda",
    link:
      "https://www.goodreads.com/book/show/22370154-autobiography-of-a-yogi-by-paramahansa-yogananda",
  },
  {
    name: "The Discovery of India - Jawaharlal Nehru",
    link: "https://www.goodreads.com/book/show/154126.The_Discovery_of_India",
  },
]
