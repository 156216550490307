import React from "react"
import { Container, SEO } from "../components"
import { InternalLink, ExternalLink } from "../components/shared"
import { people, music, books } from "../lists"

import dp from "../../content/images/displayPic.jpg"

const About = () => {
  return (
    <Container title="Me">
      <SEO />
      <div className="about">
        <h1>About me</h1>
        <div className="full">
          <img src={dp} className="dp" alt="Krishna Prasanth Vemuganti" />
        </div>
        <p>
          I&apos;m{" "}
          <strong className="special">Krishna Prasanth Vemuganti</strong>, a
          full stack developer by profession &#129492;&#127995; , and a dreamer
          at heart &#129505;.&nbsp; Check out my{" "}
          <InternalLink Link="/resume" Name="Professional Experience" />.
          <br />
          <br />
          Here I will be sharing my learnings, experiences, musings about
          technology, books, and everything I come across in simple language and
          ways to make it easily understandable.
        </p>
        {/* <section className="noMargin marginTopForty">
          <h2>Fan of</h2>
          <ul>
            <li>everything & anything about computers & India</li>
            <li>filter coffee</li>
            <li>books, music, movies & tv shows</li>
            <li>video games</li>
          </ul>
        </section> */}
        <section className="noMargin">
          <h2>My Toolkit</h2>
          <ul className="noMargin">
            <li>
              <strong>Dev Machine</strong> - Mac Mini M2
            </li>
            <li>
              <strong>Languages</strong> -{" "}
              <span className="bold">
                <span className="java">Java</span>,{" "}
                <span className="cs">C#</span>,{" "}
                <span className="php">PHP</span>,{" "}
                <span className="python">Python</span>,{" "}
                <span className="js">JavaScript</span>,{" "}
                <span className="node">Node</span>,{" "}
                <span className="react">React</span>,{" "}
                <span className="angular">Angular</span>
              </span>
            </li>
            <li>
              <strong>IDE</strong> -{" "}
              <ExternalLink
                Link="https://code.visualstudio.com/"
                Name="Visual Studio Code"
              />
            </li>
            <li>
              <strong>Frameworks</strong> -{" "}
              <ExternalLink Link="https://reactjs.org/" Name="React" /> &{" "}
              <ExternalLink Link="https://www.gatsbyjs.org/" Name="Gatsby" />
            </li>
            <li>
              <strong>Host</strong> -{" "}
              <ExternalLink Link="https://www.netlify.com/" Name="Netlify" />
            </li>
            <li>
              <strong>Markdown Editor</strong> -{" "}
              <ExternalLink Link="https://typora.io/" Name="Typora" />
            </li>
          </ul>
        </section>
        <p className="marginTopForty">
          Below are a few of my inspirations and all-time favorites...
        </p>
        <section className="noMargin">
          <h2>People</h2>
          <ul className="noMargin">
            {people.map((person) => (
              <li key={person.name}>
                <ExternalLink Link={person.link} Name={person.name} />
              </li>
            ))}
          </ul>
        </section>
        <section className="noMargin">
          <h2>Books & Authors</h2>
          <ul className="noMargin">
            {books.map((book) => (
              <li key={book.name}>
                <ExternalLink Link={book.link} Name={book.name} />
              </li>
            ))}
          </ul>
        </section>
        <section className="noMargin">
          <h2>Music</h2>
          <ul className="noMargin">
            {music.map((note) => (
              <li key={note.name}>
                <ExternalLink Link={note.link} Name={note.name} />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Container>
  )
}

export default About
