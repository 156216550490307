export default [
  {
    name: "The Vedas",
    link: "https://www.ancient.eu/The_Vedas/",
    type: "History",
    show: true,
  },
  {
    name: "Sanskrit: the first programming language?",
    link:
      "https://medium.com/@tomgoldenberg/sanskrit-the-first-programming-language-d8647753217f",
    type: "Information",
    show: true,
  },
  {
    name: "Elements of Style by Douglas Crockford",
    link: "https://www.crockford.com/style.html",
    type: "Language and Literature",
    show: true,
  },
]
