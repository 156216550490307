export default [
  { name: "AR Rehman", link: "https://en.wikipedia.org/wiki/A._R._Rahman" },
  {
    name: "Ilaiyaraaja",
    link: "https://en.wikipedia.org/wiki/Ilaiyaraaja",
  },
  {
    name: "S P Balasubrahmanyam",
    link: "https://en.wikipedia.org/wiki/S._P._Balasubrahmanyam",
  },
  {
    name: "Kishore Kumar",
    link: "https://en.wikipedia.org/wiki/Kishore_Kumar",
  },
  {
    name: "Mukesh",
    link: "https://en.wikipedia.org/wiki/Mukesh_(singer)",
  },
]
